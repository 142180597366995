<template>
    <section id="card-actions">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <!-- card actions -->
                <b-col cols="8">
                    <b-card no-body class="border-primary">
                        <div class="m-1">
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group label="Ingresar Código de Cotización" label-for="search-invoice">
                                        <b-input-group class="input-group-merge">
                                            <b-form-input ref="searchQuote" size="sm"
                                                id="search-invoice" v-model="searchQuery" autocomplete="off" autofocus 
                                                placeholder="Ingresa o escanea el código de barras"
                                                :readonly="articles != undefined && articles.length > 0"
                                                @keydown.enter="searchQuotes"/>
                                                <b-input-group-append>
                                                <b-button v-if="!searchQuery" size="sm" variant="outline-primary">
                                                    <feather-icon icon="SearchIcon" />
                                                </b-button>

                                                <b-button v-else size="sm" @click="resetForm" variant="outline-danger">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>    
                                </b-col>
                                <b-col cols="8">
                                    <b-button size="sm" @click="$router.push({name: 'apps-inventories-transfer-vouchers'})" variant="primary" class="text-end float-right mr-1 mt-1">
                                        Regresar
                                    </b-button>
                                </b-col>

                                <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Almacén Solicitante" label-for="warehouse">
                                        <v-select :disabled="!isAdministrator && wareHouseId !== 0 && wareHouseId !== null" v-model="form.almacen_Origen_Id" :options="wareHouses" label="nombre" 
                                            :reduce="option => option.almacen_Id" placeholder="Selecciona un almacen" class="select-size-sm"
                                            :clearable="false"
                                            @input="selectWarehouse"/>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Almacén Suministrador" label-for="warehouse">
                                        <v-select v-model="form.almacen_Destino_Id" :options="destinationWarehouses" label="nombre" :reduce="option => option.almacen_Id" placeholder="Selecciona un almacen" class="select-size-sm" 
                                            :clearable="false"/>
                                    </b-form-group>
                                </b-col>

                                <!-- <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Vale se asigna al chofer" label-for="operator">
                                        <v-select v-model="form.chofer_Id" :options="operators" :reduce="option => option.id" label="label" placeholder="Selecciona un chofer" class="select-size-sm"
                                        :clearable="false" />
                                    </b-form-group>
                                </b-col> -->

                                <b-col v-if="articles.length > 0" cols="12" md="12">
                                    <global-articles :articles="articles" :loading="loading" :isSticky="true" />
                                </b-col>
                            </b-row>
                        </div>
                        
                        <b-card-footer v-if="articles.length > 0">
                            <b-button ref="saveButton" :block="isMobile" @click="store"
                                size="sm" variant="primary" class="text-end float-right mr-1"
                                v-b-tooltip.hover.right="showTooltip ? `Solicitar F12` : ``">
                                <feather-icon icon="PlusIcon" />
                                CONFIRMAR SOLICITUD
                            </b-button>

                            <b-button :block="isMobile" @click="resetForm" size="sm" variant="outline-secondary" class="text-end float-right mr-1">
                                <feather-icon icon="XCircleIcon" />
                                Limpiar
                            </b-button>
                        </b-card-footer>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </section>
</template>

<script>
import _ from 'lodash'
import {
    BRow, BCol, BCard, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import store from '@/store';

import GlobalArticles from '@/@core/components/GlobalArticles.vue'

import WebOperadoresProvider from '@/providers/WebOperadores';
const WebOperadoresResource = new WebOperadoresProvider();

import TransferVouchersProvider from '@/providers/TransferVouchers'
const TransferVouchersResource = new TransferVouchersProvider()

export default {
    name: 'TransferVouchersAdd',
    components: {
        BRow, BCol, BCard, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
        BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
        BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem,BTooltip, VBTooltip,

        //
        BCardActions,
        flatPickr,
        vSelect,
        GlobalArticles,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    data() {
        return {
            loading: false,
            searchQuery: '',
            articles: [],
            wareHouses: [],
            destinationWarehouses: [],
            operators: [],
            isMobile: false,
            form: {
                almacen_Origen_Id: null,
                almacen_Destino_Id: null,
                chofer_Id: null,
            }
            
        }
    },
    computed: {
        isAdministrator() {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol']
        },
        wareHouseId() {
            return store.getters['auth/getWarehouseId']
        },
        showTooltip() {
            return this.findSetting('ACTIVAR_ATAJOS_TECLADO')
        }
    },
    async mounted() {
        // Escucha el evento de teclado cuando el componente esté montado
        window.addEventListener('keydown', this.handleKeyPress);
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        this.wareHouses = store.getters['auth/getWareHouses']
        this.destinationWarehouses = store.getters['auth/getWareHouses']

        //Obtener el armacen del usuario
        const wareHouseId = store.getters['auth/getWarehouseId']
        if (wareHouseId && wareHouseId > 0) {
            this.form.almacen_Origen_Id = wareHouseId
            this.destinationWarehouses = this.destinationWarehouses.filter(item => item.almacen_Id != wareHouseId)
        }
        
        // await this.getOperators()
    },
    beforeDestroy() {
        // Limpia el listener de eventos cuando el componente se destruya
        window.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleKeyPress(event) {
            if (this.findSetting('ACTIVAR_ATAJOS_TECLADO')) {
                // Verifica si la tecla presionada es F12
                if (event.key === 'F12') {

                    event.preventDefault();  // Prevenir la acción predeterminada del navegador

                    // Emula el clic en el botón de guardar
                    this.$refs.saveButton.click();
                }
            }
        },
        selectWarehouse(evt) {
            this.destinationWarehouses = []
            this.form.almacen_Destino_Id = null
            const warehouses = store.getters['auth/getWareHouses']
            this.destinationWarehouses = warehouses.filter(item => item.almacen_Id != evt)

        },
        async getOperators() {           
            if (this.operators.length <= 0) {
                this.loading = true
                const { data } = await WebOperadoresResource.index({})
                this.loading = false
                if (data.isSuccesful) {
                    this.operators = data.data.map(item => ({...item, label: `${item.nombre} ${item.apellidos}`})).filter(item => item.activo && item.tipo == 'Chofer' && item.id != 1)
                }
            }        
        },
        async searchQuotes() {
            this.loading = true
            try {
                const { data } = await TransferVouchersResource.findByDoctoVeId(this.searchQuery)
                if (data.isSuccesful) {
                    this.articles = data.data
                } else {
                    this.searchQuery = ''
                    this.danger(data.message)
                }
            }catch(e) {
                this.searchQuery = ''
                this.handleResponseErrors(e)
            }
            finally {
                this.loading = false
            }
        },
        async store() {
            if (!this.form.almacen_Origen_Id) {
                this.danger('Selecciona un almacén solicitante')
                return
            }

            if (!this.form.almacen_Destino_Id) {
                this.danger('Selecciona un almacén suministrador')
                return
            }

            // if (!this.form.chofer_Id) {
            //     this.danger('Selecciona un chofer')
            //     return
            // }

            this.loading = true
            const wareHouses = store.getters['auth/getWareHouses']
            this.articles.forEach(item => {
                item.empresa_Id = store.getters['auth/getConexionId']
                item.sucursal_Id = store.getters['auth/getBranch'].sucursal_Id
                item.usuario_Id = store.getters['auth/getUser'].id
                item.chofer_Id = this.form.chofer_Id
                item.almacen_Origen_Id = this.form.almacen_Origen_Id
                item.almacen_Origen = wareHouses.find(wh => wh.almacen_Id == this.form.almacen_Origen_Id).nombre
                item.almacen_Destino_Id = this.form.almacen_Destino_Id
                item.almacen_Destino = wareHouses.find(wh => wh.almacen_Id == this.form.almacen_Destino_Id).nombre
                item.fecha_Alta = new Date()
                item.fecha_Actualizacion = new Date()
                item.tipo_Mov = 'P'
                item.cerrada = false
                item.um = item.unidad_Venta
                item.clave_Articulo = item.clave_Articulo
                item.unidades_Pedidas = item.unidades
            })

            try {
                const { data } = await TransferVouchersResource.store(this.articles)
                if (data.isSuccesful) {
                    this.success(data.message)
                    await this.$router.push({name: 'apps-inventories-transfer-vouchers'})
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.handleResponseErrors(e)
            }
            finally {
                this.loading = false
            }
        },
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        },
        resetForm(){
            this.searchQuery = ''
            this.articles = []
            this.$nextTick(() => {
                this.$refs.searchQuote.focus()
            })
        }
    },
    watch: {

    }
}

</script>